<template>
  <div id="LeetCodeClassification">
    <div class="banner">
      <img src="@/assets/img/LeetCodeClassification/banner.png" alt="" />
    </div>
    <div class="lcContainer">
      <div class="leftTree">
        <div
          :class="['classes', activeId == lcClass.id ? 'active' : '']"
          v-for="(lcClass, index) in lcClasses"
          :key="index"
          @click="changeActiveClass(lcClass.id)"
        >
          {{ lcClass.name }}
        </div>
      </div>
      <div class="rightContainer">
        <div
          class="questions"
          v-for="(question, index) in rightResults"
          :key="index"
        >
          {{ question }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLCClasses, getClassDetail } from "@/service/leetcodeClassification";
export default {
  name: "LeetCodeClassification",
  data() {
    return {
      searchKey: "",
      lcClasses: [],
      activeId: "",
      rightResults: [],
    };
  },
  methods: {
    getLCClasses() {
      getLCClasses().then((res) => {
        if (res.success) {
          this.lcClasses = res.result;
          this.activeId = res.result[0].id;
          this.changeActiveClass(this.activeId);
        }
      });
    },
    changeActiveClass(id) {
      this.activeId = id;
      getClassDetail(id).then((res) => {
        if (res.success) {
          this.rightResults = res.result;
        }
      });
    },
  },
  mounted() {
    this.getLCClasses();
  },
};
</script>
<style lang="scss" scoped>
#LeetCodeClassification {
  width: 100%;
  .banner {
    width: 100%;
    img {
      width: 100%;
      vertical-align: top;
    }
  }
  .searchArea {
    background: #f8f8f8;
    padding: 20px 16px;
  }
  .lcContainer {
    display: flex;
    .leftTree {
      width: 30%;
      .classes {
        line-height: 44px;
        background: #f8f8f8;
        padding: 0 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        &.active {
          background: #fff;
          &::before {
            position: absolute;
            content: "";
            width: 3px;
            height: 20px;
            background: #0a7aff;
            border-radius: 2px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .rightContainer {
      width: 70%;
      padding: 16px 20px;
      .questions {
        margin-bottom: 12px;
      }
    }
  }
}
</style>